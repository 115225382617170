//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictionary,
         getLang,
         retrieveButter } from '~/utils/butterUtils'
export default {
  name: '',
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/about-us.vue asyncData ')
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    isProductionSite () {
      return process.env.stage === 'prod'
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
  },
  created () {
    if (process.env.stage==='prod') {
      this.$router.push(this.localePath({ name: 'index' }))
    }
  },
  methods: {
    doshowCFM () {
      this.showCFM = !this.showCFM
      this.$store.commit('saveShowCodeFragmentMark', this.showCFM)
    },
  },
}
